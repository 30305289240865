/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../i18n';
import { Textarea } from '../../inputs/Textarea';
import { Checkbox } from '../../inputs/Checkbox';
import { TextInput } from '../../inputs/TextInput';
import { Button } from '../../main/Button';
import { AttachInput, AttachInputValue } from '../../inputs/AttachInput';

interface IProps {
  onSubmit: (fields: Record<string, any>) => void;
}

const OrderConfirmModal: FC<IProps> = ({ onSubmit }) => {
  const { messages } = useIntl();

  const [budget, setBudget] = useState(messages['order.budget_1'].toString());
  const [features, setFeatures] = useState('');
  const [task, setTask] = useState('');
  const [design, setDesign] = useState('');
  const [stack, setStack] = useState('');
  const [prototypes, setPrototypes] = useState('');
  const [characteristics, setCharacteristics] = useState('');
  const [show_task, setShowTask] = useState(false);
  const [show_design, setShowDesign] = useState(false);
  const [show_stack, setShowStack] = useState(false);
  const [show_prototypes, setShowPrototypes] = useState(false);
  const [file, setFile] = useState<AttachInputValue>({
    name: '',
    content: null,
  });

  const [disabled, setDisabled] = useState(false);
  useEffect(() => setTask(''), [show_task]);
  useEffect(() => setDesign(''), [show_design]);
  useEffect(() => setStack(''), [show_stack]);
  useEffect(() => setPrototypes(''), [show_prototypes]);

  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setDisabled(true);

      onSubmit({
        budget: budget.replace(/&nbsp;/gim, ' '),
        file: file.content || new ArrayBuffer(0),
        filename: file.name,
        features,
        task,
        stack,
        prototypes,
        design,
        characteristics,
      });
    },
    [
      onSubmit,
      budget,
      features,
      task,
      design,
      stack,
      file,
      prototypes,
      characteristics,
    ]
  );

  const featuresHandler = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setFeatures(e.target.value);
  const characteristicsHandler = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setCharacteristics(e.target.value);

  return (
    <div className={styles.content}>
      <form onSubmit={onFormSubmit} id="order-confirm-form">
        <h1>{t('order.additional_questions')}</h1>

        <div className={styles.subtitle}>{t('order.additional_subtitle')}</div>

        <div className={styles.line} />

        <h4>{t('order.feature_list')}</h4>

        <Textarea
          name="features"
          value={features}
          handler={featuresHandler}
          placeholder="order.feature_placeholder"
        />

        <h4>{t('order.materials')}</h4>

        <div className={styles.technical}>
          <label>
            <Checkbox checked={show_task} handler={setShowTask} />
            {t('order.technical_task')}
          </label>

          {show_task && (
            <TextInput
              value={task}
              handler={setTask}
              name="task_link"
              placeholder={messages['order.technical_task_link'].toString()}
            />
          )}
        </div>

        <div className={styles.technical}>
          <label>
            <Checkbox checked={show_stack} handler={setShowStack} />
            {t('order.technology_stack')}
          </label>

          {show_stack && (
            <TextInput
              value={stack}
              handler={setStack}
              name="stack_link"
              placeholder={messages['order.technology_stack_link'].toString()}
            />
          )}
        </div>

        <div className={styles.technical}>
          <label>
            <Checkbox checked={show_prototypes} handler={setShowPrototypes} />
            {t('order.prototypes')}
          </label>

          {show_prototypes && (
            <TextInput
              value={prototypes}
              handler={setPrototypes}
              name="prototypes_link"
              placeholder={messages['order.prototypes_link'].toString()}
            />
          )}
        </div>

        <div className={styles.technical}>
          <label>
            <Checkbox checked={show_design} handler={setShowDesign} />
            {t('order.design')}
          </label>

          {show_design && (
            <TextInput
              value={design}
              handler={setDesign}
              name="design_link"
              placeholder={messages['order.design_link'].toString()}
            />
          )}
        </div>

        <h4>{t('order.characteristics')}</h4>

        <Textarea
          name="characteristics"
          value={characteristics}
          handler={characteristicsHandler}
          placeholder="order.feature_placeholder"
        />

        <h4>{t('order.budget_title')}</h4>

        <div className={styles.budget}>
          {[...new Array(4)].map((_, i) => (
            <div
              className={classnames({
                [styles.active]:
                  messages[`order.budget_${i + 1}`].toString() === budget,
              })}
              onClick={() =>
                setBudget(messages[`order.budget_${i + 1}`].toString())
              }
              key={i}
            >
              {t(`order.budget_${i + 1}`)}
            </div>
          ))}
        </div>

        <AttachInput value={file} handler={setFile} />

        <div className={styles.button}>
          <Button disabled={disabled} isLoading={disabled}>
            {t('order.send_request')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export { OrderConfirmModal };
